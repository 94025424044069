.switch-custom .css-1n7gs2l-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
     background-color: rgb(37 99 235) !important;
     height: 20px !important;
}
.margin-top {
     margin-top: 0rem;
 }
 
 @media (max-width: 1027px) and (min-width: 643px) {
     .margin-top {
         margin-top: 20rem;
     }
 }
 
 @media (max-width: 643px) {
     .margin-top {
         margin-top: 0rem;
     }
 }
 

