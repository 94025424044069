@media (min-width: 250px) {
    .flex-custom {
            display: flex !important;
        }
    .block-custom{
        display: block !important;
    }
}
@media (min-width: 768px) {
    .hidden-custom {
        display: flex !important;
    }
}
.custom-color-primary{
    background-color: #1199FF !important;
}

/* .show-flex-before-900{
    display: none;
  } */
  .show-block-before-900{
    display: none;
  }
  
  @media  (min-width: 900px) {
    .show-flex-before-900{
      display: flex !important;
    }
    .show-block-before-900{
      display: block !important;
    }
  }